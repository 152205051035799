.App {
  display: flex;
  height: 100vh;
}

.vertical-section {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
} 

.divExperiment{
  margin-top:20px;
  padding:20px;
  background-color:#eeeeee;
  border:1px solid #dddddd;
}

.divFlex{
  display:flex;
}

.floatLeft{
  float:left;
}

.buttonFilter{
  font-family: "Source Sans Pro", sans-serif;
  font-size:16px;
  letter-spacing:1px;
  color:white;
  background-color:#33a933;
  width:180px;
  height:20px;
  padding-top:5px;
  cursor:pointer;
  text-align:center;
  margin:5px;
  border-top:none;
  border-bottom:4px solid #177217;
}

.preview-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  margin: 0;
}

.preview-image-wrapper {
  width: 20%;
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

.preview-image {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
}
.arrows{
  display: flex;
    flex-direction: column;
    width: 55%;
    position: relative;
    left: 40%;
    height: 100%;
    top: -77%;
}
.arrow-icon{
  font-size: 30px;
}
.arrows button{
  width: 40px;
  background-color: #ffffff;
  border: 0px;
}
.up-down{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 105px;
}
.right-arrow{
  text-align: right;
}
.left-arrow{
  text-align: left;
}
.left-right{
  display: flex;
    width: 28%;
    position: relative;
    bottom: 69px;
    left: -45px;
    justify-content: space-between;
    align-items: center;
}
/* .previewdiv{
  width: 370px;
} */


.color-box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 20px;
}
.color-div{
  display:flex;
  align-items:center;
  justify-content:center;
      margin-right: 20px;
  }
  
  .button-div{
  display:flex;
  flex-direction:column;
      margin-left: 5px;
  }

.image-fiter-style
{
  height: 358;
  width: auto,
}
.arrows-column{
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-style
{
  margin-top: 15px;
}

.reactcrop-style{
  margin-top: 15px;
}

.zoom-style
{
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}


#image-container {
  padding: 0;
  margin: 0;
}

#image-container {
  width: auto; /* Set to auto or remove if not needed */
  height: auto; /* Set to auto or remove if not needed */
}
